div#profile-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  place-content: center;
  place-items: center;
}
div#profile-card div#profile-card-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 100%;
  align-items: center; 
}
div#profile-card div#profile-card-detail * {
  line-height: 1.6rem;
}
div#profile-card svg#favourite-icon {
  position: absolute;
}

ul#my-tools {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 0;
}
ul#my-tools li.MuiListItem-root {
  padding: 0;
  width: auto;
}
ul#my-tools li.MuiListItem-root img {
  max-width: 32px;
  max-height: 32px;
}
ul#my-tools ul#more-tools {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
ul#my-tools ul#more-tools img#jquery-fix-width {
  max-width: 84px;
}