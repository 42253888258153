@font-face {
  font-family: "Poppins";
  src: url("./theme/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./theme/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./theme/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: bold;
}




body {
  margin: 0;
  height: 100vh;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}
body #root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  line-height: 1.6rem;
  /* text-underline-offset: 0.25rem; */
}
body main {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}
body main section.MuiContainer-root {
  padding: 0;
}
body footer {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}